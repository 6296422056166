const Menu = [
  {
    heading: "menu",
    route: "/assessment",
    pages: [
      {
        heading: "Assessment Dashboard",
        route: "/assessment/assessment-dashboard",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Search",
        route: "/assessment/assessment-search",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Training Assessment",
        route: "/assessment/training-assessment",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Assessment List",
        route: "/assessment/assessment-list",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
    ],
  },
];

export default Menu;
